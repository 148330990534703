import React, { Component } from "react";

function Footer() {
  return (
    <div className="col-md-4 mb-0 text-muted">
      Made by the greatest person EVAR!
    </div>
  );
}

export default Footer;
